// https://github.com/encharm/Font-Awesome-SVG-PNG

.heatmapsToolbarButton {
	background-image: url(images/fire_black.png);
	background-size: 32px;
	background-repeat: no-repeat;
	background-position: center;

	&.pointCloud {
		background-image: url(images/pointcloud_black.svg);
	}
}

.heatmapsToolbarButtonBlue {
	background-image: url(images/fire_68cff2.png);
	background-size: 32px;
	background-repeat: no-repeat;
	background-position: center;
	box-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, 1);

	&.pointCloud {
		background-image: url(images/pointcloud_blue.svg);
	}
}

#heatmapScaleContainer {
	position: absolute;
	top: 45px;
	left: 0;
	text-align: left;
	height: 225px;
}

#scanDateContainer {
	width: max-content;
	border-radius: 5px;
	color: #fff;
	background-color: rgba(43, 41, 41, 0.4);
	font-size: 14px;
	padding: 8px;
	margin: 0 5px;
	display: flex;
	flex-direction: row;
}

#heatmapScale {
	width: 50px;
	height: 180px;
	position: relative;
}

#heatmapScaleValue {
	width: 44px;
	text-align: center;
	white-space: nowrap;
	background-color: rgba(255, 255, 255, 0.95);
	border-radius: 3px;
	margin-left: 3px;
	font-weight: bolder;
	font-size: 11px;
	vertical-align: middle;
	float: left;
}

#heatmapSetterContainer {
	width: 50px;
	height: 175px;
	background: #ffffff;
	border-radius: 5px;
	box-shadow: 1px 3px 10px 0 rgba(0, 0, 0, 0.4);
	position: absolute;
	left: 0;
	top: 481px;
}

.deviationContainer {
	font-weight: 600;
	font-size: 10px;
	height: 22px;
	text-align: center;
	margin: auto;
}

.tooltiptext {
	visibility: hidden;
	width: 100px;
	background-color: white;
	text-align: center;
	padding: 5px;
	border-radius: 3px;
	position: absolute;
	top: -25px;
	left: 25px;
	font-size: 11px;
	z-index: 10;
}

#heatmapToleranceLabel {
	width: 34px;
	height: 20px;
	background-image: url(images/caliper.png);
	opacity: 0.9;
	position: relative;
	top: 11px;
	margin-left: 8px;
	margin-bottom: 2px;
	float: left;
}

#heatmapToleranceLabel:hover .tooltiptext {
	visibility: visible;
}

#heatmapToleranceInput {
	width: 38px;
	height: 18px;
	position: relative;
	top: 11px;
	margin-bottom: 16px;
	text-align: center;
	font-size: 12px;
	float: left;
	box-shadow: none;
	border: 1px solid #dbdbdb;
	border-radius: 2px;
}

#heatmapMaxDeviationLabel {
	width: 34px;
	height: 20px;
	background-image: url(images/range.png);
	opacity: 0.9;
	position: relative;
	top: 11px;
	margin-left: 8px;
	margin-bottom: 2px;
	float: left;
}

#heatmapMaxDeviationLabel:hover .tooltiptext {
	visibility: visible;
}

#heatmapMaxDeviationInput {
	width: 38px;
	height: 18px;
	position: relative;
	top: 9px;
	margin-bottom: 16px;
	text-align: center;
	font-size: 12px;
	float: left;
	box-shadow: none;
	border: 1px solid #dbdbdb;
	border-radius: 2px;
}

#heatmapResetButton {
	width: 32px;
	min-width: 32px;
	max-width: 32px;
	height: 32px;
	position: relative;
	top: 10px;
	left: 4px;
	box-shadow: none;
	border: 0;
	outline: 0;
	background: url('images/reset.png') no-repeat;
	cursor: pointer;
}

.heatmapMagnitudeTooltip {
	display: flex;
	gap: 5px;
	align-items: center;
	padding: 0 10px;
	justify-content: space-between;
	background-color: rgba(255, 255, 255, 0.9);
	border-radius: 5px;
	position: absolute;
	text-align: center;
	overflow: hidden; /* Hide the element content, while height = 0 */
	height: 0;
	opacity: 0;
	transition: height 0ms 100ms, opacity 100ms 0ms;
	z-index: 2;
}

.heatmapMagnitudeTooltip.active {
	height: 30px;
	opacity: 1;
	transition: height 0ms 0ms, opacity 200ms 0ms;
}

.heatmapMagnitudeTooltip p {
	font-weight: bolder;
	text-align: center;
}

.heatmapMagnitudeTooltip button {
	cursor: pointer;
	border: none;
	height: 10px;
	width: 10px;
	display: block;
	padding: 6px;
	border-radius: 5px;
	background: none;

	img {
		display: block;
	}

	&:hover {
		background: var(--sr-color-hover);
	}
}

// Loading spinner
// https://loading.io/css/
.lds-ring {
	display: inline-block;
	width: 64px;
	height: 64px;
	transform: rotate(45deg);
	transform-origin: 32px 32px;

	margin: auto;
	position: fixed;
	top: 50%;
	left: 50%;
	margin-top: -50px;
	margin-left: -50px;
	visibility: visible;
	text-align: center;
}

.lds-ring div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 101px;
	height: 101px;
	border: 10px solid;
	border-radius: 50%;
	animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
	animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
	animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
	animation-delay: -0.15s;
}

@keyframes lds-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
// END loading spinner
