.issue-card {
	&.open {
		border-left: #ffa600 5px solid;

		.issue-status-badge {
			background: #ffa600;
			color: white;
		}
	}

	&.under-review {
		border-left: #087cd9 5px solid;

		.issue-status-badge {
			background: #087cd9;
			color: white;
		}
	}

	&.closed {
		border-left: #bcc9d1 5px solid;

		.issue-status-badge {
			background: #bcc9d1;
			color: white;
		}
	}
}
