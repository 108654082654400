.docking-panel-content {
	position: relative;
	height: calc(100% - 70px);
	width: 100%;
}

.adsk-viewing-viewer #toolbar-explodeTool {
	display: none !important;
}

// Do not display the forge spinner, since we are using our own global loading indicator on top of it
.forge-viewer .forge-spinner {
	display: none !important;
}

.split-mode .viewcubeWrapper {
	display: none;
}

.disable-click {
	pointer-events: none;
}

//ContextMenu Divider Styling
.adsk-viewing-viewer .docking-panel.menu .menu-divider-container {
	cursor: default;
	height: 0px;
}

.adsk-viewing-viewer .docking-panel.menu .menu-divider {
	cursor: default;
	height: 1px;
	margin-bottom: 64px;
	margin-top: 0px;
	position: absolute;
	width: 100%;
}

.adsk-viewing-viewer.light-theme .docking-panel.menu .menu-divider {
	background-color: #e1e3e5;
}
