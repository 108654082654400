.ToggleClassificationsToolbarButton {
	background-image: url(images/toggle-classifications.svg);
	background-size: 32px;
	background-repeat: no-repeat;
	background-position: center;

	&.active {
		background-image: url(images/toggle-classifications_active.svg);
	}
}

#ToggleClassificationsPanel {
	background-color: white;

	.docking-panel-content {
		height: 100%;
	}
}
