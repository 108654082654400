.propertiesTooltipToolbarButton {
	background-image: url(images/propertiesTooltip.svg);
	background-size: 32px;
	background-repeat: no-repeat;
	background-position: center;
}

.propertiesTooltipToolbarButtonBlue {
	background-image: url(images/propertiesTooltipBlue.svg);
	background-size: 32px;
	background-repeat: no-repeat;
	background-position: center;
	box-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, 1);
}
