// https://github.com/encharm/Font-Awesome-SVG-PNG

.photoSphereToolbarButton {
	background-image: url(images/camera_black.png);
	background-size: 32px;
	background-repeat: no-repeat;
	background-position: center;
}

.photoSphereToolbarButtonBlue {
	background-image: url(images/camera_68cff2.png);
	background-size: 32px;
	background-repeat: no-repeat;
	background-position: center;
	box-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, 1);
}

#ThreeSixtyPhotoPanel {
	background-color: white;

	.docking-panel-content-wrapper {
		height: 100%;
		width: 100%;
	}

	.image-container img {
		max-width: 100%;
		max-height: 100%;
	}

	.image-toggle-bounding-box {
		background-color: rgba(255, 255, 255, 0.65);
		border-radius: 8px;
		width: 180px;
		height: 32px;
		position: absolute;
		margin-top: 8px;
		left: 8px;
		display: table;

		span {
			display: table-cell;
			text-align: center;
			vertical-align: middle;
			font-size: 13px;
			font-weight: bolder;
		}
	}

	.image-toggle-switch {
		width: 32px;
		height: 32px;
		margin-left: 8px;
		cursor: pointer;

		&[data-switched-on='true'] {
			background-image: url(images/switch-on.png);
		}

		&[data-switched-on='false'] {
			background-image: url(images/switch-off.png);
		}
	}

	.image-navigation-container {
		background-color: rgba(255, 255, 255, 0.65);
		border-radius: 8px;
		width: 105px;
		height: 32px;
		position: absolute;
		margin-top: 8px;
		left: calc(50% - 45px);
		display: table;
	}

	.image-navigation-left {
		width: 32px;
		height: 32px;
		background-image: url(images/arrow-left.png);
		display: table;
		float: left;
		cursor: pointer;
	}

	.image-navigation-right {
		width: 32px;
		height: 32px;
		background-image: url(images/arrow-right.png);
		display: table;
		float: left;
		cursor: pointer;
	}

	.image-navigation-counter {
		width: 40px;
		height: 32px;
		display: table;
		float: left;
	}

	.image-navigation-text {
		width: 40px;
		display: table-cell;
		text-align: center;
		vertical-align: middle;
		font-size: 13px;
		font-weight: bolder;
	}

	.image-date {
		background-color: rgba(255, 255, 255, 0.65);
		border-radius: 8px;
		width: 165px;
		height: 32px;
		position: absolute;
		margin-top: 8px;
		right: 8px;
		display: table;

		span {
			display: table-cell;
			text-align: center;
			vertical-align: middle;
			font-size: 13px;
			font-weight: bolder;
		}
	}
}
