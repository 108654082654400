:root {
	// This styles are for AWS Amplify
	--button-background-color: green !important;
	--button-click: darkgreen !important;
	--ion-color-primary-tint: darkolivegreen !important;
	--link-color: steelblue !important;
}

.adsk-viewing-viewer .adsk-control-tooltip {
	text-transform: none !important;
}
