// https://github.com/encharm/Font-Awesome-SVG-PNG

#VerticalToolbarExtension\.Toolbar\.Clone.inactive {
	background-image: url(images/clone_black.png);
	background-size: 32px;
	background-repeat: no-repeat;
	background-position: center;
}

#VerticalToolbarExtension\.Toolbar\.Clone.active {
	// background-image: url(images/globe.png);
	background-image: url(images/clone_68cff2.png);
	background-size: 32px;
	background-repeat: no-repeat;
	background-position: center;
	box-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, 1);
}

#divToolbar {
	position: fixed;
	z-index: 100;
	left: 0;
	top: 70px;
	bottom: 80px;
	width: 50px;

	#toolbar-TtIf {
		top: 0;
		position: relative;
		height: 100%;
		width: 100%;
	}

	#verticalControlGroup {
		position: absolute;
		top: 275px;
		left: 0;
	}
}

.adsk-viewing-viewer {
	//Override viewer font
	font-family: 'Inter', sans-serif;
}
