// *******************************************
// ********* Commons styles house ************
// *******************************************

// Importing the Inter Font from Google cdn
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap');

// Importing color variables
@import 'colors/sr-colors';

// ***********************************
// ******** General styles ***********
// ***********************************
.disabled {
	opacity: 0.2;
}

button {
	border-radius: 4px;
	&[disabled],
	&.no-interaction {
		pointer-events: none;
	}
	&[role='tab'] {
		&:active,
		&:focus {
			box-shadow: none;
		}
	}
	&.no-bg-btn,
	&.icon-btn {
		padding: 4px 12px;
		box-shadow: none !important;
		background-color: initial;
		border: none;
		width: fit-content;
		height: fit-content;
		&:hover {
			background-color: var(--sr-color-focus);
		}
		&.icon-btn {
			padding: 6px;
			line-height: 0;
		}
	}
}
